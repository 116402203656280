<template>
  <div class="cpt-MMC_Gimbal_Z40N" :style="containerStyle">
    <div v-interact class="hd">
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">高清变焦相机</div>
      </div>
      <div class="close" @click="$emit('close')">关闭</div>
    </div>
    <div class="bd">
      <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">控制速度:</div>
          <div class="input-box">
            <div class="speedC">
              <div
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                class="speed"
                :style="
                  control_speed == index + 1
                    ? 'background: #FFFFFF;color: #333333;'
                    : ''
                "
                @click="control_speed = index + 1"
              >
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">云台模式:</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holderModelDom == 0 }"
                  @click="handle_change_ptz_mode(0)"
                />
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holderModelDom == 1 }"
                  @click="handle_change_ptz_mode(1)"
                />
                <div
                  class="mono-right mono_box"
                  :class="{ active: holderModelDom == 2 }"
                  @click="handle_change_ptz_mode(2)"
                />
              </div>
              <div class="text">
                <div class="text-left">锁定</div>
                <div class="text-right">跟随</div>
                <div class="text-right">回中</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item" style="margin-top: 10px">
          <div class="label-box" style="width: 68px; text-align: right">
            分辨率:
          </div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-short">
                <div
                  class="mono-left mono_box"
                  :class="{ active: activeBox3 == 1 }"
                  @click="set_defog(1)"
                ></div>
                <div
                  class="mono-right mono_box"
                  :class="{ active: activeBox3 == 0 }"
                  @click="set_defog(0)"
                ></div>
              </div>
              <div class="text">
                <div class="text-left">1080P</div>
                <div class="text-right"></div>
                <div class="text-right">4K</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="form-item">
          <div class="label-box">视频指点:</div>
          <div class="input-box">
            <el-radio-group v-model="dj_mode" @change="handle_change_dj_mode">
              <el-radio
                v-for="item in drd.dj_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
              >{{ item.label }}</el-radio>
            </el-radio-group>
          </div>
        </div> -->
        <div class="form-item">
          <div class="label-box">
            变&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;焦:
          </div>
          <div class="input-box">
            <div
              class="mr10"
              style="cursor: pointer"
              @mousedown="handle_zoom(-1)"
              @mouseup="stopChange"
              @mouseout="stopChange"
            >
              <span class="iconfont icon-shanchu3" />
            </div>
            <el-slider
              v-model="value"
              :max="50"
              :min="0"
              :show-tooltip="false"
              style="width: 10px"
              disabled
              @change="stopChange"
              @input="onChangezoom"
            />
            <div
              class="ml10"
              style="cursor: pointer"
              @mousedown="handle_zoom(1)"
              @mouseup="stopChange"
              @mouseout="stopChange"
            >
              <span class="iconfont icon-tianjia1" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            俯&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;仰:
          </div>
          <div class="input-box">
            <div class="speedC">
              <div
                v-for="(item, index) in regList"
                :key="index"
                class="pitch"
                :style="
                  pitch == item.num ? 'background: #FFFFFF;color: #333333;' : ''
                "
                @click="handle_change_pitch(item.num)"
              >
                {{ item.val }}
              </div>
            </div>
            <!-- <el-input
              v-model.number="pitch"
              size="mini"
              placeholder="请输入俯仰"
            />
            <span class="iconfont icon-shanchu3" @click="pitch--"></span>
            <span
              class="iconfont icon-tianjia1 mr5 ml3"
              @click="pitch++"
            ></span>-->

            <!-- <el-button
              @click="pitch--"
              size="mini"
              icon="el-icon-minus"
              circle
            ></el-button>
            <el-button
              @click="pitch++"
              size="mini"
              icon="el-icon-plus"
              circle
            ></el-button>-->

            <!-- <div class="butto" @click="handle_change_pitch" size="mini" type="primary"
              >设置</div
            >-->
          </div>
        </div>
        <!-- <div class="form-item">
          <div class="label-box">
            航&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;向:
          </div>
          <div class="input-box">
            <el-input
              v-model.number="yaw"
              size="mini"
              placeholder="请输入航向"
            />
            <span class="iconfont icon-shanchu3" @click="yaw--"></span>
            <span class="iconfont icon-tianjia1 mr5 ml3" @click="yaw++"></span>
            <div class="butto" @click="handle_change_yaw" size="mini" type="primary"
              >设置</div
            >
          </div>
        </div>-->
        <div class="form-item">
          <div class="label-box">相机模式</div>
          <div class="input-box">
            <el-radio-group
              v-model="camera_mode"
              @change="handle_change_camera_mode"
            >
              <el-radio
                v-for="item in drd.camera_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="form-item" style="margin-top: 10px">
          <div class="label-box" style="width: 68px; text-align: right">
            防抖:
          </div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-short">
                <div
                  class="mono-left mono_box"
                  :class="{ active: fdactive == 0 }"
                  @click="anti_shake(0)"
                ></div>
                <div
                  class="mono-right mono_box"
                  :class="{ active: fdactive == 1 }"
                  @click="anti_shake(1)"
                ></div>
              </div>
              <div class="text">
                <div class="text-left">关闭</div>
                <div class="text-right"></div>
                <div class="text-right">打开</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item" style="margin-top: 10px">
          <div class="label-box" style="width: 68px; text-align: right">
            OSD:
          </div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-short">
                <div
                  class="mono-left mono_box"
                  :class="{ active: osdactive == 0 }"
                  @click="ia_mode(0)"
                ></div>
                <div
                  class="mono-right mono_box"
                  :class="{ active: osdactive == 1 }"
                  @click="ia_mode(1)"
                ></div>
              </div>
              <div class="text">
                <div class="text-left">关闭</div>
                <div class="text-right"></div>
                <div class="text-right">打开</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <div class="item-group">
          <div class="item-box" @click="handle_take_photo">
            <el-tooltip
              class="item"
              effect="dark"
              content="拍照"
              placement="top"
            >
              <div class="icon-box">
                <!-- <span class="iconfont icon-paizhao1"></span> -->
                <img src="~@/assets/images/mount/photo.png" alt />
              </div>
            </el-tooltip>
          </div>
          <div class="item-box" @click="handle_record">
            <el-tooltip
              class="item"
              effect="dark"
              content="录像"
              placement="top"
            >
              <div class="icon-box">
                <!-- <span class="iconfont icon-dandianshipin"></span> -->
                <img src="~@/assets/images/mount/record.png" alt />
              </div>
            </el-tooltip>
          </div>
        </div>
        <div ref="rocker" class="rocker">
          <div
            class="shangUp"
            @mousedown="fangxiang('up')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
          <div
            class="xaiUp"
            @mousedown="fangxiang('down')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
          <div
            class="zuoUp"
            @mousedown="fangxiang('right')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
          <div
            class="youUp"
            @mousedown="fangxiang('left')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cal_crc_table, MMC_Gimbal_Z40N } from "../utils";
export default {
  props: {
    containerStyle: {
      type: Object,
      default: () => ({}),
    },
    position: {
      type: Object,
      default: () => ({}),
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Array,
      default: () => [],
    },
    selected_mount: {
      type: Object,
      default: () => ({}),
    },
    moveType: {
      type: String,
      default: () => "",
    },
    offline: {
      type: Boolean,
      default: () => true,
    },
    keyFlag: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    stream() {
      return this.$store.state.fckernel.stream;
    },
  },
  data() {
    return {
      osdactive: 1,
      fdactive: 0,
      activeBox3: 1,
      holderModelDom: 1,
      hdrModeDom: 1,
      radio: 1,
      value: 25,
      value2: 25,
      control_speed: 3,
      ptz_mode: 0,
      zoom: 1,
      pitch: 0,
      yaw: 0,
      camera_mode: 0,
      record: false,
      dj_mode: 0,
      regList: [
        {
          val: "复位",
          num: 0,
        },
        {
          val: "30",
          num: 30,
        },
        {
          val: "60",
          num: 60,
        },
        {
          val: "90",
          num: 90,
        },
      ],
      drd: {
        dj_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        ptz_mode: [
          { id: 0, label: "锁定" },
          { id: 1, label: "跟随" },
          { id: 2, label: "回中" },
        ],
        camera_mode: [
          { id: 0, label: "录像" },
          { id: 2, label: "拍照" },
        ],
      },
      timer: null,
      time: null,
    };
  },
  watch: {
    payload: {
      handler(value) {
        this.resolve_payload(value);
      },
      deep: true,
    },
  },
  beforeDestroy() {
    /* this.$store.commit("device/SET_MOVE_DATA", {
      ...this.device,
      mountStatus: 0,
    }); */
  },
  methods: {
    handle_change_dj_mode(id) {
      console.log(this.selected_mount, "selected_mount");
      /* this.$store.commit("device/SET_MOVE_DATA", {
        ...this.device,
        mountStatus: id,
        mountId: this.selected_mount.mountId || this.selected_mount.mountType,
        moveType: this.moveType,
        name: this.selected_mount.name,
      }); */
    },
    onChangezoom(e) {
      if (this.value2 < this.value) {
        this.handle_zoom(1);
      } else if (this.value2 > this.value) {
        this.handle_zoom(-1);
      }
      this.value2 = e;
    },
    stopChange() {
      this.value = 25;
      this.value2 = 25;
      // this.handle_zoom(this)
      const buffer = MMC_Gimbal_Z40N.zoom((this.zoom = 1));
      this.commit_directive(buffer);
    },
    resolve_payload(buff) {
      try {
        const dataArray = new Uint8Array(buff);
        // console.log(dataArray,'dataArray');
        if (
          dataArray[0] === 0xa5 &&
          cal_crc_table(dataArray) === dataArray[dataArray.length - 1]
        ) {
          switch (dataArray[1]) {
            case 0x01:
              this.msg_key_status1(dataArray);
              break;
            case 0xf8:
              this.gcs_button_ctrl(dataArray);
              break;
          }
        }
      } catch (error) {}
    },
    gcs_button_ctrl(data) {
      const button = data[3];
      const status = data[4];

      switch (button) {
        case 4:
          this.gimbal_mode_ctrl(status);
          break;
        default:
          break;
      }
    },
    msg_key_status1(data) {
      this.gimbal_mode_ctrl(data[7] & 0x3);
    },
    gimbal_mode_ctrl(mode) {
      this.ptz_mode = mode;
    },

    handle_change_ptz_mode(value) {
      this.holderModelDom = value;
      const buffer = MMC_Gimbal_Z40N.gimbal_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    set_defog(val) {
      this.activeBox3 = val;
      const buffer = MMC_Gimbal_Z40N.set_defog(val);
      this.commit_directive(buffer);
    },
    anti_shake(val) {
      this.fdactive = val;
      const buffer = MMC_Gimbal_Z40N.anti_shake(val);
      this.commit_directive(buffer);
    },
    ia_mode(val) {
      this.osdactive = val;
      const buffer = MMC_Gimbal_Z40N.ia_mode(val);
      this.commit_directive(buffer);
    },

    handle_change_hdr_mode(value) {
      this.hdrModeDom = value;
      const buffer = MMC_Gimbal_Z40N.camera_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_zoom(value) {
      const buffer = MMC_Gimbal_Z40N.zoom(this.zoom + value);
      this.commit_directive(buffer);
      // buffer = MMC_Gimbal_Z40N.zoom((this.zoom = 1));
      // this.commit_directive(buffer);
    },
    handle_change_pitch(num) {
      this.pitch = num;
      const buffer = MMC_Gimbal_Z40N.change_pitch_angle(this.pitch);
      this.commit_directive(buffer);
    },
    handle_change_yaw() {
      const buffer = MMC_Gimbal_Z40N.change_yaw_angle(this.yaw);
      this.commit_directive(buffer);
    },
    handle_change_camera_mode(value) {
      const buffer = MMC_Gimbal_Z40N.camera_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_take_photo() {
      // const { position } = this;
      // const buffer = MMC_Gimbal_Z40N.take_photo(position || {});
      // this.commit_directive(buffer);
      // this.$emit('take_photo', 'MMC_Gimbal_Z40N');
      if (!this.keyFlag) return this.$message.error("拍照失败，板载通信异常！");
      let streamData = {
        data: {
          data: {
            videoID: 1,
          },
        },
        type: 528,
      };
      if (this.stream == "QingLiu") {
      streamData.data.messageID = 1016;
      } else {
        streamData.data.messageID = 1007;
      }
      this.$emit("take_photo", streamData);
    },
    handle_record() {
      if (!this.keyFlag) return this.$message.error("录像失败，板载通信异常！");
      this.record = !this.record;
      let streamData = {
        data: {
          data: {
            videoID: 1,
          },
        },
        type: 528,
      };
      if (this.stream == "QingLiu") {
      streamData.data.messageID = 1017;
      streamData.data.data.status = this.record;
      } else {
        streamData.data.messageID = 1006;
        streamData.data.data.recordControl = this.record;
      }
      this.$emit("record", streamData);
      // this.record = !this.record;
      // const buffer = MMC_Gimbal_Z40N.record(this.record);
      // this.commit_directive(buffer);
    },
    commit_directive(buffer) {
      console.log(buffer);
      this.$emit("directive", buffer);
    },
    fangxiang(type) {
      clearTimeout(this.time);
      let buffer = null;
      this.flag = true;
      if (this.flag) {
        this.time = setTimeout(() => {
          this.fangxiang(type);
        }, 500);
      }
      switch (type) {
        case "up":
          buffer = MMC_Gimbal_Z40N.gimbal_pitch_ctrl(1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case "left":
          buffer = MMC_Gimbal_Z40N.gimbal_yaw_ctrl(1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case "right":
          buffer = MMC_Gimbal_Z40N.gimbal_yaw_ctrl(-1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case "down":
          buffer = MMC_Gimbal_Z40N.gimbal_pitch_ctrl(-1, this.control_speed);
          this.commit_directive(buffer);
      }
      // this.timer = setTimeout(() => {
      //   clearTimeout(this.timer)
      //   buffer = MMC_Gimbal_Z40N.gimbal_pitch_ctrl(0, this.control_speed);
      //   this.commit_directive(buffer);
      //   buffer = MMC_Gimbal_Z40N.gimbal_yaw_ctrl(0, this.control_speed);
      //   this.commit_directive(buffer);
      // }, 500);
    },
    stopfxFn() {
      this.flag = false;
      clearTimeout(this.time);
      let buffer = null;
      buffer = MMC_Gimbal_Z40N.gimbal_pitch_ctrl(0, this.control_speed);
      this.commit_directive(buffer);
      buffer = MMC_Gimbal_Z40N.gimbal_yaw_ctrl(0, this.control_speed);
      this.commit_directive(buffer);
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_Z40N {
  position: absolute;
  top: 100px;
  right: 0px !important;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  width: 470px;
  height: 290px;
  box-sizing: border-box;

  // padding: 10px 20px;
  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;

    .left {
      display: flex;
      align-items: center;

      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .bd {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    height: 230px;

    overflow-y: auto;
    overflow-x: hidden;

    .form-wrap {
      flex-shrink: 0;

      .form-item {
        display: flex;
        align-items: center;
        height: 40px;

        .label-box {
          margin-right: 14px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
        }

        .input-box {
          display: flex;
          align-items: center;

          .mono {
            display: flex;
            flex-direction: column;

            .mono-long {
              display: flex;
              justify-content: space-between;
              width: 168px;
              height: 17px;
              background: url("~@/assets/images/mount_long.png") no-repeat;
              background-size: 100%;
              margin-bottom: 5px;

              .mono_box {
                width: 28px;
                height: 13px;
                border-radius: 3px;
                margin-top: 2px;
                cursor: pointer;
              }

              .active {
                background: url("~@/assets/images/mount/mount_control.png")
                  no-repeat !important;
                background-size: 100% 100%;
              }
            }
            .mono-short {
              display: flex;
              justify-content: space-between;
              width: 108px;
              height: 17px;
              background-size: 100%;
              margin-bottom: 5px;
              background: url("~@/assets/images/mount_short.png") no-repeat;
              .mono_box {
                width: 28px;
                height: 13px;
                border-radius: 3px;
                margin-top: 2px;
                cursor: pointer;
              }

              .active {
                background: url("~@/assets/images/mount/mount_control.png")
                  no-repeat !important;
                background-size: 100% 100%;
              }
            }

            .text {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #ffffff;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
            }
          }
        }
      }
    }

    .rocker-wrap {
      flex: 1;

      .item-group {
        display: flex;
        justify-content: space-evenly;

        .item-box {
          .icon-box {
            width: 32px;
            height: 32px;
            background: #000000;
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            .iconfont {
              font-size: 24px;
              color: #dce9ff;
              cursor: pointer;
            }
          }
        }
      }

      .rocker {
        margin: 10px auto 0;
        width: 150px;
        height: 150px;
        position: relative;
        margin: 0 auto;
        background: center url("~@/assets/images/accident/caozuo.png") no-repeat;
        background-size: 100% 100%;

        .shangUp {
          position: absolute;
          left: 37%;
          top: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .xaiUp {
          position: absolute;
          left: 37%;
          bottom: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .zuoUp {
          position: absolute;
          left: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .youUp {
          position: absolute;
          right: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}

::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;

    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #004fff;
        color: #dce9ff;
      }
    }
  }

  .el-input {
    width: 130px;
    margin-right: 5px;

    .el-input__inner {
      background: #000000;
      border: 1px solid #004fff;
      color: #dce9ff;
    }
  }

  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #004fff;
            background: #004fff;
          }
        }
      }

      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }

  .butto {
    width: 35px;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
    text-align: center;
    background: #004fff;
    color: #000;
    border-radius: 5px;
  }

  .el-button {
    width: 35px;
    height: 25px;
    text-align: center;
    background: #2aefed;
    color: #000;
    border: none;
  }
}

::v-deep .el-slider__bar {
  background: #fff;
}

.speedC {
  display: flex;
  width: 173px;
  height: 24px;
  background: rgba(13, 34, 79, 0.65);
  border-radius: 12px;
  border: 1px solid #427dff;

  .speed {
    width: 34px;
    // background: #FFFFFF;
    color: #ffffff;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
  }

  .pitch {
    font-size: 12px;
    margin-left: 12px;
    width: 34px;
    height: 24px;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
    color: #fff;
  }
}

.iconfont {
  color: #004fff;
}

// 变焦功能
::v-deep .el-slider__runway {
  visibility: hidden;
}
</style>
